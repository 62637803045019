import {Component, Input} from '@angular/core';
import {faArrowLeft, faArrowsRotate} from '@fortawesome/free-solid-svg-icons';
import {ConversationsService} from '../services/conversations.service';
import {MatDialog} from '@angular/material/dialog';
import {UploadDialogComponent} from '../upload-dialog/upload-dialog.component';
import {ChatroomService} from "../services/chatroom.service";

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent {
    @Input() type: string = '';
    @Input() name: string | null = null;
    @Input() phone_number: string | null = null;
    loggedUserFullName: string | null = null;
    instances: Array<any> = [];
    selectedInstanceId: Number = 0;
    selectedInstance: any = {};
    faArrowsRotate = faArrowsRotate;

    constructor(
        private conversationsService: ConversationsService,
		private chatService: ChatroomService,
        public dialog: MatDialog
    ) {
    }
	back(){
		this.chatService.enterChat({
			contact: null,
			messages: [],
		});
		const urlObj = new URL(window.location.href);
		urlObj.searchParams.delete('phone');
		window.location.href = urlObj.toString();
	}

    ngOnInit(): void {
        this.loggedUserFullName = '';
        if (window.sessionStorage.getItem('first_name') !== null) {
            this.loggedUserFullName = window.sessionStorage.getItem('first_name') + ' ';
        }

        if (window.sessionStorage.getItem('last_name') !== null) {
            this.loggedUserFullName += window.sessionStorage.getItem('last_name');
        }

        if (window.sessionStorage.getItem('instances') !== null && window.sessionStorage.getItem('instance') !== null) {
            this.instances = JSON.parse(window.sessionStorage.getItem('instances') ?? '[]');
            this.selectedInstanceId = parseInt(window.sessionStorage.getItem('instance') ?? '0');
            this.selectedInstance = this.instances?.find((instance: any) => {
                return instance?.id === this.selectedInstanceId;
            });
        }
    }

    syncContact(phone_number: string | null) {
        if (phone_number) {
            this.conversationsService
                .syncContact(phone_number)
                .subscribe((data: any) => {
                    console.log(data);
                    this.name = data.full_name;
                });
        }
    }

    sendNewMessage() {
        const dialogRef = this.dialog.open(UploadDialogComponent, {
            width: 'auto',
            height: 'auto',
            data: {
                type: 'newMessage',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result);
            if (result) {
                result = result.replace(/\D/g, '');
                this.conversationsService.getConversation(
                    {
                        contact_number: result,
                    },
                    window.sessionStorage.getItem('instance')
                );
            }
        });
    }

	getInstancesList() {
		const dialogRef = this.dialog.open(UploadDialogComponent, {
			width: 'auto',
			height: 'auto',
			data: {
				type: 'instances',
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(result);
			if (result) {
				window.sessionStorage.setItem('instance', result);
				window.location.reload();
			}
		});
	}

	protected readonly faArrowLeft = faArrowLeft;
}
