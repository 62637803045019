<!-- app-chat-window -->
<div class="w-100 h-100 overlay" *ngIf="!contact"></div>
<ng-container *ngIf="contact && state">
	<app-nav-bar
		[class]="[
			'row',
			'd-flex',
			'flex-row',
			'align-items-center',
			'p-2',
			'm-0',
			'w-100'
		]"
		type="window"
		[id]="['navbar']"
		[phone_number]="contact?.contact_number"
		[name]="contact?.contact_name"
	></app-nav-bar>

	<div
		class="d-flex flex-column"
		id="messages"
		(scroll)="scrolled($event)"
		#scrollframe
	>
		<ng-container *ngFor="let message of messages; let i = index">
			<div
				class="mx-auto my-2 bg-primary text-white small py-1 px-2 rounded"
				*ngIf="
					(message.date &&
						i > 0 &&
						messages[i - 1].date != message.date) ||
					(message.date && i == 0)
				"
			>
				{{ message.date }}
			</div>
			<app-message
				#message
				[message]="message"
				p-1
				my-1
				mx-3
				rounded
				bg-white
				shadow-sm
				message-item
				[status]="message.status"
				[class]="[
					'p-1',
					'my-1',
					'mx-3',
					'rounded',
					'bg-white',
					'shadow-sm',
					'message-item',
					message.direction === 'in'
						? 'align-self-start'
						: 'align-self-end',
					message.direction === 'in' ? '' : 'self'
				]"
				[type]="message.type"
			></app-message>
		</ng-container>
	</div>

	<div
		class="d-flex flex-column"
		id="input-area"
	>
		<div
			*ngIf="!canSendMessage()"
			class="px-3 py-1 d-flex align-items-center"
			 #tooltip="matTooltip"
			 matTooltip="Once a customer messages us, we can reply without restrictions for 24 hours; Otherwise, Only approved template messages are allowed by WhatsApp."
			 [matTooltipPosition]="'before'"
			 matTooltipHideDelay="100000"
		>
			<span class="conv-closed"></span>
			<span class="small text-muted">
				Closed Conversation,
				<span (click)="chooseTemplate()" role="button" class="fw-bold text-primary pointer-event" >Click here to initiate the conversation with an approved template.</span>
			</span>
		</div>

		<div
			*ngIf="canSendMessage()"
			class="px-3 py-1 d-flex align-items-center"
			#tooltip="matTooltip"
			matTooltip="Once a customer messages us, we can reply without restrictions for 24 hours; Otherwise, Only approved template messages are allowed by WhatsApp."
			[matTooltipPosition]="'before'"
		>
			<span class="conv-open"></span>
			<span class="small text-muted">
				Open Conversation.
			</span>
		</div>

		<form
			(ngSubmit)="sendTextMessage(messageForm)"
			#messageForm="ngForm"
			class="d-flex align-items-center flex-grow-1"
		>
			<input
				[disabled]="!canSendMessage()"
				type="text"
				name="message"
				id="input"
				placeholder="Type a message"
				class="flex-grow-1 border-0 ms-3 px-3 py-2 mb-3 rounded shadow-sm"
				ngModel
			/>
			<input
				id="imageInput"
				type="file"
				accept="image/*"
				(change)="onFileSelected($event)"
				hidden
				#imageInput
			/>
			<input
				id="docInput"
				type="file"
				accept=".pdf"
				(change)="onFileSelected($event)"
				hidden
				#docInput
			/>
			<button
				type="button"
				mat-icon-button
				[matMenuTriggerFor]="aboveMenu"
			>
				<fa-icon
					[icon]="faPaperclip"
					class="text-muted"
					style="cursor: pointer"
				></fa-icon>
			</button>
			<mat-menu
				#aboveMenu="matMenu"
				yPosition="above"
				id="'attachements'"
			>
				<button [disabled]="!canSendMessage()" mat-menu-item (click)="chooseImage()">
					<mat-icon>camera_alt </mat-icon>
				</button>
				<button mat-menu-item (click)="chooseTemplate()">
					<mat-icon fontIcon="library_books"></mat-icon>
				</button>
				<button [disabled]="!canSendMessage()" mat-menu-item (click)="chooseDoc()">
					<mat-icon fontIcon="picture_as_pdf"></mat-icon>
				</button>
			</mat-menu>
			<fa-icon
				[icon]="faPaperPlane"
				class="text-muted px-3"
				style="cursor: pointer"
				(click)="sendTextMessage(messageForm)"
			></fa-icon>
		</form>
	</div>
</ng-container>
