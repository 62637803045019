/*export const environment = {
	production: false,
	apiUrl: 'https://5mouokc8d1.execute-api.eu-west-3.amazonaws.com/default/whatsapp-messenger',
	websocketUrl:
		'wss://5d11tqc3gb.execute-api.eu-west-3.amazonaws.com/production',
	zoho_check_endpoint:
		'https://tswc54omalh74thpdgmgbnuluu0dqinl.lambda-url.eu-west-3.on.aws/',
};*/
export const environment = {
	production: true,
	apiUrl: 'https://1x01kqnmnh.execute-api.eu-west-3.amazonaws.com/whatsapp-messenger',
	websocketUrl: 'wss://wwc2w65ut2.execute-api.eu-west-3.amazonaws.com/production',
	zoho_check_endpoint: 'https://7pgusylqod33m6nzz3qqgqdekq0qvlow.lambda-url.eu-west-3.on.aws/',
	ok:"ok"
};
