<ng-container *ngIf="type == 'chatlist'">
	<img
		alt="Profile Photo"
		[matMenuTriggerFor]="menu"
		class="img-fluid rounded-circle me-2"
		style="height: 50px; cursor: pointer"
		id="display-pic"
		src="assets/3670161.png"
	/>
	<mat-menu #menu="matMenu" xPosition="before">
		<button mat-menu-item (click)="getInstancesList()">Instances</button>
	</mat-menu>
	<div class="text-white font-weight-bold" id="username">Whatsapp <b>{{selectedInstance?.key}}</b> <small>
		( {{loggedUserFullName}} )</small></div>
	<button
		type="button"
		mat-icon-button
		[matMenuTriggerFor]="messageMenu"
		class="text-white ms-auto"
	>
		<mat-icon [classList]="['align-top']"> more_vert</mat-icon>
	</button>
	<mat-menu #messageMenu="matMenu" xPosition="before">
		<button mat-menu-item
				(click)="sendNewMessage()"
		>New message
		</button>
	</mat-menu>
</ng-container>

<ng-container *ngIf="type == 'window'">
	<span (click)="back()">
		<fa-icon
			[icon]="faArrowLeft"
			class="text-white"
			style="cursor: pointer"
		></fa-icon>
	</span>
	<img
		src="assets/user-512.png"
		alt="Profile Photo"
		class="img-fluid rounded-circle mr-2"
		style="height: 50px"
		id="pic"
	/>
	<div class="d-flex flex-column">
		<div class="text-white font-weight-bold" id="name">
			{{ name }}
			<span class="text-small" id="phone-number">
				{{
				"(+" +
				phone_number?.substr(0, 3) +
				" " +
				phone_number?.substr(3, 3) +
				" " +
				phone_number?.substr(6) +
				")"
				}}
			</span>
		</div>
	</div>

	<button class="btn" (click)="syncContact(phone_number)">
		<fa-icon
			[icon]="faArrowsRotate"
			class="text-white"
			style="cursor: pointer"
		></fa-icon>
	</button>
</ng-container>
