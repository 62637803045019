import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChatroomService } from '../services/chatroom.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ConversationsService {
	constructor(
		private http: HttpClient,
		private chatroomService: ChatroomService
	) {}

	getConversations(instanceId: any): Observable<any> {
		return this.http.get(`${environment.apiUrl}?instance=${instanceId}`);
	}

	getOwnConversations(instanceId: any, uid: any): Observable<any> {
		return this.http.get(
			`${environment.apiUrl}?instance=${instanceId}&uid=${uid}`
		);
	}

	getConversation(contact: any, instanceId: any): any {
		this.http
			.get(
				`${environment.apiUrl}?contact=${contact.contact_number}&instance=${instanceId}`
			)
			.subscribe(
				(data: any) => {
					this.chatroomService.enterChat({
						contact: contact,
						messages: data,
					});
				},
				(error: any) => {
					console.log(error.error);
					this.chatroomService.enterChat({
						contact: contact,
						messages: [],
					});
				}
			);
	}

	searchConversations(query: any, instanceId: any): Observable<any> {
		return this.http.get(
			`${environment.apiUrl}?instance=${instanceId}&search=${query}`
		);
	}

	syncContact(contact: any): Observable<any> {
		return this.http.post(`${environment.zoho_check_endpoint}sync`, {
			org: new URLSearchParams(window.location.search).get('org'),
			contact: contact,
		});
	}
}
