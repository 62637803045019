<img
	src="assets/user-512.png"
	alt="Profile Photo"
	class="img-fluid rounded-circle me-2"
	style="height: 50px"
/>
<div class="w-50">
	<div class="text-truncate name">
		{{ contact.contact_name ?? "+" + contact.contact_number.substr(0, 3) + " " + contact.contact_number.substr(3, 3) + " " + contact.contact_number.substr(6) }}
	</div>
	<div class="small last-message">
		<fa-icon
			[icon]="
					contact?.status === 'pending'
						? faClock
						: contact?.status === 'sent'
						? faCheck
						: contact?.status === 'delivered' || contact?.status === 'read'
						? faCheckDouble
						: faCircleExclamation
				"
			[style.color]="contact?.status === 'read' ? '#5dd1ee' : '#555555'"
			*ngIf="contact?.direction == 'out'"
		></fa-icon>
		<ng-container *ngIf="contact.type == 'text' || contact.type == 'template'">
			{{ contact.content }}
		</ng-container>
		<ng-container *ngIf="contact.type == 'image'">
			<fa-icon [icon]="faImage"></fa-icon>
			Photo
		</ng-container>
		<ng-container *ngIf="contact.type == 'document'">
			<fa-icon [icon]="faFile"></fa-icon>
			Document
		</ng-container>
		<ng-container *ngIf="contact.type == 'audio'">
			<fa-icon [icon]="faMicrophone"></fa-icon>
			Audio
		</ng-container>
	</div>
</div>
<div class="flex-grow-1 text-end">
	<div class="small time">
		{{ contact.formatted_date_time }}
	</div>
	<div>
		<fa-icon *ngIf="contact?.direction === 'in'"
				 [icon]="faBell"
				 [style.color]="'#ff3800'"
				 	>

		</fa-icon>
	</div>
</div>
