import { Component, Input } from '@angular/core';
import { mDate } from '../helpers/DateHelper';
import {
	faImage,
	faFile,
	faMicrophone,
	faCheck,
	faClock,
	faCheckDouble,
	faCircleExclamation, faBell
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent {
    faImage = faImage;
    faFile = faFile;
    faMicrophone = faMicrophone;

    @Input() contact: any = {};

    constructor() { }

    ngOnInit(): void {
        if (this.contact.date_time) {
            // this.contact.date_time = new Date(this.contact.date_time);
            // console.log(this.contact);
            this.contact.formatted_date_time = mDate(this.contact.date_time).chatListFormat();
        }
    }

    ngOnChanges(): void {
        if (this.contact.date_time) {
            this.contact.formatted_date_time = mDate(this.contact.date_time).chatListFormat();
        }
    }


	protected readonly faCheck = faCheck;
	protected readonly faClock = faClock;
	protected readonly faCheckDouble = faCheckDouble;
	protected readonly faCircleExclamation = faCircleExclamation;
	protected readonly faBell = faBell;
}
