<ng-container *ngIf="data.type == 'image'">
	<h1 mat-dialog-title>Upload Image</h1>
	<div mat-dialog-content>
		<img [src]="data.file" alt="image" height="350px" width="auto" />
	</div>
	<div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
		<button mat-button class="text-primary" (click)="confirmUpload()">Upload</button>
	</div>
</ng-container>

<ng-container *ngIf="data.type == 'document'">
	<h1 mat-dialog-title>Upload Document</h1>
	<div mat-dialog-content>
		<fa-icon [icon]="faFileLines" size="6x"></fa-icon>
	</div>
	<div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
		<button mat-button class="text-primary" (click)="confirmUpload()">Upload</button>
	</div>
</ng-container>

<ng-container *ngIf="data.type == 'template'">
	<h1 mat-dialog-title>Send Template</h1>
	<div mat-dialog-content>
		<p>Choose a template to send</p>
		<select #templateSelect required>
			<option *ngFor="let template of templates" [value]="template.content">
                {{ template.alias }}
            </option>
		</select>
	</div>
	<div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
		<button mat-button class="text-primary" (click)="sendTemplate(templateSelect.value)">Send</button>
	</div>
</ng-container>

<ng-container *ngIf="data.type == 'instances'">
	<h1 mat-dialog-title>Choose instance</h1>
	<div mat-dialog-content>
		<p>Choose an instance to send</p>
		<select required #instanceSelect>
			<option *ngFor="let instance of instances" [value]="instance.id">
				{{ instance.key }}
			</option>
		</select>
	</div>
	<div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
		<button mat-button class="text-primary" (click)="selectInstance(instanceSelect.value)">
			Send
		</button>
	</div>
</ng-container>

<ng-container *ngIf="data.type == 'newMessage'">
    <h1 mat-dialog-title>New Message</h1>
    <div mat-dialog-content>
        <p>Entrez le numéro de téléphone du destinataire</p>
        <input type="text" #phoneNumber required />
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
        <button mat-button class="text-primary" (click)="sendNewMessage(phoneNumber.value)">
            Send
        </button>