import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent {
	title = 'kifal-whatsapp';

	constructor() {}

	ngOnInit(): void {
		if (!new URL(window.location.href).searchParams.get('uid')) {
			sessionStorage.clear();
		}
	}
}
