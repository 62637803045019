<app-nav-bar
	[class]="['row', 'd-flex', 'flex-row', 'align-items-center', 'p-2']"
	[id]="['navbar']"
	type="chatlist"
></app-nav-bar>

<div class="side_content">
	<div class="search_chat">
		<div>
			<input type="text" placeholder="Search or start new chat" #search />
			<fa-icon [icon]="faSearch"></fa-icon>
		</div>
	</div>
	<div
		class="list-choice d-flex justify-content-around border-bottom p-2"
		*ngIf="state"
	>
		<div class="list-choice-item d-flex flex-column">
			<input
				type="radio"
				name="choice"
				value="all"
				checked
				(change)="selectConv($event)"
			/>
			<span class="text-center fs-7">All conversations</span>
		</div>
		<div class="list-choice-item d-flex flex-column">
			<input
				type="radio"
				name="choice"
				value="own"
				(change)="selectConv($event)"
			/>
			<span class="text-center fs-7">My conversations</span>
		</div>
	</div>
	<div class="row m-auto" id="chat-list" *ngIf="contacts.length > 0 && state">
		<app-contact
			*ngFor="let contact of contacts"
			[class]="[
				'chat-list-item',
				'd-flex',
				'flex-row',
				'w-100',
				'p-2',
				'border-bottom'
			]"
			[style.background-color]="contact?.direction === 'in' ? '#FEF5E7':''"
			[contact]="contact"
			(click)="selectContact(contact)"
		></app-contact>
	</div>
</div>
