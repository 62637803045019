import {
	Component,
	ViewChild,
	ElementRef,
	EventEmitter,
	Input,
	AfterViewChecked,
	ViewChildren,
	QueryList,
	OnInit,
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {mDate} from '../helpers/DateHelper';
import {WebSocketService} from '../services/web-socket.service';
import {ChatroomService} from '../services/chatroom.service';
import {faPaperPlane, faPaperclip, faMessage} from '@fortawesome/free-solid-svg-icons';
import {MatDialog} from '@angular/material/dialog';
import {UploadDialogComponent} from '../upload-dialog/upload-dialog.component';
import {environment} from 'src/environments/environment';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-chat-window',
	templateUrl: './chat-window.component.html',
	styleUrls: ['./chat-window.component.css'],
})
export class ChatWindowComponent implements AfterViewChecked, OnInit {
	@ViewChild('imageInput') imageInput: ElementRef | undefined;
	@ViewChild('docInput') docInput: ElementRef | undefined;
	@ViewChild('scrollframe') scrollFrame: ElementRef | undefined;
	@ViewChildren('message') messageElements: QueryList<any> | undefined;
	@Input() state: boolean = false;

	templateListener: EventEmitter<any> = new EventEmitter<any>();

	faPaperPlane = faPaperPlane;
	faPaperclip = faPaperclip;
	faMessage = faMessage;

	contact: any = null;

	messages: any[] = [];

	private first = true;
	private scrollContainer: any;
	private isNearBottom = true;

	constructor(
		private webSocketService: WebSocketService,
		private chatroomService: ChatroomService,
		private http: HttpClient,
		public dialog: MatDialog
	) {
	}

	ngAfterViewChecked() {
		this.scrollContainer = this.scrollFrame?.nativeElement;
		if (this.first && this.scrollContainer && this.contact) {
			// this.onmessageElementsChanged();
			this.scrollToBottom();
		}
	}

	private onmessageElementsChanged(): void {
		if (this.isNearBottom) {
			this.scrollToBottom();
		}
	}

	private scrollToBottom(): void {
		this.scrollContainer.scroll({
			top: this.scrollContainer.scrollHeight,
			left: 0,
			behavior: this.first ? 'instant' : 'smooth',
		});
		if (this.first && this.scrollContainer.scrollHeight > 0)
			this.first = false;
	}

	private isUserNearBottom(): boolean {
		const threshold = 150;
		const position =
			this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
		const height = this.scrollContainer.scrollHeight;
		return position > height - threshold;
	}

	scrolled(event: any): void {
		this.isNearBottom = this.isUserNearBottom();
	}

	ngOnInit(): void {
		this.chatroomService.getChat().subscribe((data: any) => {
			this.first = true;
			this.contact = data.contact;
			this.messages = data.messages;
			this.messages.forEach((message: any) => {
				if (message.date_time) {
					message.time = mDate(message.date_time).getTime();
					message.date = mDate(message.date_time).getDate();
				}
			});
		});

		this.webSocketService.dispatchMessage().subscribe((data: any) => {
			this.handleMessage(data);
		});

		this.webSocketService.dispatchMessageStatus().subscribe((data: any) => {
			this.messages.forEach((message: any) => {
				if (message.message_id === data.message_id) {
					let priorities = ['pending', 'sent', 'delivered', 'read'];
					if (
						priorities.indexOf(message.status) <
						priorities.indexOf(data.message_status)
					) {
						message.status = data.message_status;
					}
				}
			});
		});

		this.templateListener.subscribe((data: any) => {
			this.messages.push({
				message_id: data.message_id,
				content: data.content,
				date_time: new Date(),
				direction: 'out',
				status: 'pending',
				type: data.type,
			});
		});

		if (this.scrollContainer) {
			this.messageElements?.changes.subscribe((_) => {
				console.log(this.messageElements?.changes);
				this.onmessageElementsChanged();
			});
		}
	}

	sendTextMessage(form: NgForm) {
		const url = new URL(window.location.href);
		if (form.value.message?.toString()?.trim()?.length == 0 || !this.canSendMessage()) return;

		const message: any = {
			instance: sessionStorage.getItem('instance'),
			contact_number: this.contact.contact_number,
			type: 'text',
			content: form.value.message,
			sender: window.sessionStorage.getItem('connectionId'),
		};
		message.date_time = new Date();
		message.direction = 'out';
		message.status = 'pending';

		this.messages.push(message);

		this.http.post(environment.apiUrl, message).subscribe(
			(data: any) => {
				message.message_id = data.message_id;
			},
			(error: any) => {
				console.log(error.error);
			}
		);

		form.reset();
	}

	handleMessage(data: any) {
		if (window.sessionStorage.getItem('instance') == data.instance_id) {
			if (this.contact?.contact_number === data.message_sender) {
				this.messages.push({
					content: data.message_content,
					date_time: new Date(Number(data.message_time) * 1000),
					direction: 'in',
					message_id: data.message_id,
					type: data.message_type,
				});
			}
		}
	}

	chooseImage() {
		this.imageInput?.nativeElement.click();
	}

	chooseDoc() {
		this.docInput?.nativeElement.click();
	}

	chooseTemplate() {
		const dialogRef = this.dialog.open(UploadDialogComponent, {
			width: '500px',
			height: 'auto',
			data: {
				contact: this.contact,
				type: 'template',
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			// console.log(result);
			if (result) {
				this.messages.push({
					message_id: result.message_id,
					content: result.content,
					date_time: new Date(),
					direction: 'out',
					status: 'pending',
					type: result.type,
				});
			}
		});
	}

	onFileSelected(event: any) {
		let reader = new FileReader();
		if (event.target.files && event.target.files.length > 0) {
			let file = event.target.files[0];
			reader.readAsDataURL(file);
			reader.onload = () => {
				const dialogRef = this.dialog.open(UploadDialogComponent, {
					width: '500px',
					height: 'auto',
					data: {
						file: reader.result,
						contact: this.contact,
						type:
							event.target.id === 'imageInput'
								? 'image'
								: 'document',
					},
					panelClass: ['d-flex', 'flex-column', 'align-items-center'],
				});
				dialogRef.afterClosed().subscribe((result) => {
					// console.log(result);
					if (result) {
						this.messages.push({
							message_id: result.message_id,
							content: result.content,
							date_time: new Date(),
							direction: 'out',
							status: 'pending',
							type: result.type,
						});
					}
				});
			};
		}
	}

	canSendMessage(): boolean {
		return this.messages.some((message: any) => {
			return message?.direction === "in" && (Math.abs(Date.now() - Date.parse(message?.date_time)) / 36e5) < 23.8
		})
	}

}
