import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './chat/chat.component';
import { HttpClientModule } from '@angular/common/http';
import { MessageComponent } from './message/message.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatWindowComponent } from './chat-window/chat-window.component';
import { ContactComponent } from './contact/contact.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
	declarations: [
		AppComponent,
		ChatComponent,
		MessageComponent,
		NavBarComponent,
		ChatListComponent,
		ChatWindowComponent,
		ContactComponent,
		SideMenuComponent,
		UploadDialogComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		FontAwesomeModule,
		BrowserAnimationsModule,
		MatMenuModule,
		MatButtonModule,
        MatIconModule,
        MatDialogModule,
		MatTooltipModule
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
