import { Component, Input } from '@angular/core';
import { mDate } from '../helpers/DateHelper'
import {
	faCheck,
	faCheckDouble,
	faClock,
    faFileLines,
    faCircleExclamation
} from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.css'],
})
export class MessageComponent {
    faClock = faClock;
    faCheck = faCheck;
    faCheckDouble = faCheckDouble;
    faFileLines = faFileLines;
    faCircleExclamation = faCircleExclamation;
    
    @Input() direction: string = '';
    @Input() type: string = '';
    @Input() message: any = null;
    @Input() status: string = '';

    constructor() { }
    
    // ngOnInit(): void {
    //     if (this.message && this.message.date_time) {
    //         this.message.time = mDate(this.message.date_time).getTime();
    //         this.message.date = mDate(this.message.date_time).getDate();
    //     }
    // }
}
