import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TemplatesService {
	constructor(private http: HttpClient) {}

	getTemplates(): Observable<any> {
		return this.http.get(
			`${
				environment.apiUrl
			}?type=templates&connection_id=${window.sessionStorage.getItem(
				'connectionId'
			)}&instance=${window.sessionStorage.getItem(
				'instance'
			)}`
		);
	}
}
