import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ChatroomService {

    private subject = new Subject<any>();

    constructor() { }

    enterChat(contact: any) {
        this.subject.next(contact);
    }

    getChat(): Observable<any> {
        return this.subject.asObservable();
    }
}
