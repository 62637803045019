import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { TemplatesService } from '../services/templates.service';

@Component({
	selector: 'app-upload-dialog',
	templateUrl: './upload-dialog.component.html',
	styleUrls: ['./upload-dialog.component.css'],
})
export class UploadDialogComponent implements OnInit {
	faFileLines = faFileLines;
	template: string = '';
	instances: any[] = [];
	templates: any[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { file: string; contact: any; type: string },
		public dialogRef: MatDialogRef<UploadDialogComponent>,
		private http: HttpClient,
		private templateService: TemplatesService
	) {}

	confirmUpload() {
		let formData = new FormData();

		let dataUrl = this.data.file;

		let mimeType = dataUrl.split(';')[0].split(':')[1];

		fetch(dataUrl)
			.then((res) => res.blob())
			.then((blob) => {
				let file = new Blob([blob], { type: mimeType });
				formData.append('content', file);
				formData.append(
					'contact_number',
					this.data.contact.contact_number
				);
				formData.append('type', this.data.type);

				formData.append(
					'instance',
					window.sessionStorage.getItem('instance') ?? ''
				);
				const url = new URL(window.location.href);
				formData.append(
					'sender',
					window.sessionStorage.getItem('connectionId') ?? ''
				);

				this.http
					.post(environment.apiUrl, formData)
					.subscribe((data: any) => {
						this.dialogRef.close(data);
					});
			});
	}

	sendTemplate(template: any) {
		let formData = new FormData();
		formData.append('contact_number', this.data.contact.contact_number);
		formData.append('type', this.data.type);
		formData.append('content', template);
		formData.append(
			'instance',
			window.sessionStorage.getItem('instance') ?? ''
		);
		const url = new URL(window.location.href);
		formData.append(
			'sender',
			window.sessionStorage.getItem('connectionId') ?? ''
		);
		this.http.post(environment.apiUrl, formData).subscribe((data: any) => {
			this.dialogRef.close(data);
		});
	}

	selectInstance(instance: any) {
		this.dialogRef.close(instance);
    }
    
    sendNewMessage(phone_number: string) {
        this.dialogRef.close(phone_number);
    }

	ngOnInit(): void {
		if (this.data.type === 'instances') {
			this.instances = JSON.parse(
				window.sessionStorage.getItem('instances') ?? '[]'
			);
		} else if (this.data.type === 'template') {
			this.templateService.getTemplates().subscribe((data: any) => {
				this.templates = data.map((template: any) => {
					return {
						alias: template.alias,
						content: JSON.stringify({
							language_code: template.language_code,
							params: template.params,
							name: template.name,
						}),
					};
				});
			});
		}
	}
}
