import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import { WebSocketService } from '../services/web-socket.service';
import { ConversationsService } from '../services/conversations.service';
import { of } from 'rxjs';
import {
	debounceTime,
	map,
	distinctUntilChanged,
	filter,
} from 'rxjs/operators';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { fromEvent } from 'rxjs';

@Component({
	selector: 'app-chat-list',
	templateUrl: './chat-list.component.html',
	styleUrls: ['./chat-list.component.css'],
})
export class ChatListComponent implements OnInit {
	@ViewChild('search', { static: true }) search: ElementRef | undefined;
	@Input() state: boolean = false;

	faSearch = faSearch;
	contacts: any[] = [];

	constructor(
		private webSocketService: WebSocketService,
		private conversationsService: ConversationsService
	) {}

	selectContact(contact: any) {
		this.conversationsService.getConversation(
			contact,
			window.sessionStorage.getItem('instance')
		);
	}

	ngOnInit(): any {
		let waitForinstance = setInterval(() => {
			// console.log('waiting for instance');
			if (window.sessionStorage.getItem('instance')) {
				clearInterval(waitForinstance);
				this.conversationsService
					.getConversations(window.sessionStorage.getItem('instance'))
					.subscribe((data: any) => {
						if (data != 'No messages found') this.contacts = data;
						else this.contacts = [];
						console.log(this.contacts);
						const url = new URL(window.location.href);
						const phone = url.searchParams.get('phone');
						if (phone) {
							console.log(phone);
							let found = false;
							this.contacts.forEach((contact: any) => {
								if (contact.contact_number === phone) {
									this.selectContact(contact);
									found = true;
									return;
								} else {
									console.log('not found');
									this.selectContact({
										contact_name: 'New Contact',
										contact_number: phone,
										content: '',
									});
								}
							});
						}
						// console.log(data);
					});
			}
		}, 100);

		this.webSocketService.dispatchMessage().subscribe((data: any) => {
			this.handleMessage(data);
		});

		this.webSocketService.dispatchMessageStatus().subscribe((data: any) => {
			this.contacts.forEach((message: any) => {
				if (message.message_id === data.message_id) {
					message.status = data.message_status;
				}
			});
		});

		fromEvent(this.search?.nativeElement, 'keyup')
			.pipe(
				map((event: any) => {
					return event.target.value;
				}),
				debounceTime(400),
				distinctUntilChanged()
			)
			.subscribe((text: string) => {
				// if (text.length > 0) {
				this.conversationsService
					.searchConversations(
						text,
						window.sessionStorage.getItem('instance')
					)
					.subscribe(
						(data: any) => {
							this.contacts = data;
							console.log(data);
						},
						(error: any) => {
							this.contacts = [];
							console.log(error.error);
						}
					);
				// }
			});
	}

    selectConv(e: any) { 
        this.contacts = [];
		console.log(e);
		if (e.target.value == 'all') {
			this.conversationsService
				.getConversations(window.sessionStorage.getItem('instance'))
				.subscribe((data: any) => {
					if (data != 'No messages found') this.contacts = data;
					else this.contacts = [];
					console.log(this.contacts);
				});
		} else if (e.target.value == 'own') {
			this.conversationsService
				.getOwnConversations(
					window.sessionStorage.getItem('instance'),
					window.sessionStorage.getItem('uid')
				)
				.subscribe((data: any) => {
					if (data != 'No messages found') this.contacts = data;
					else this.contacts = [];
					console.log(this.contacts);
				});
		}
	}

	handleMessage(data: any) {
		if (window.sessionStorage.getItem('instance') == data.instance_id) {
			let found = false;
			this.contacts.forEach((contact: any) => {
				if (contact.contact_number === data.message_sender) {
					let newDate = new Date(Number(data.message_time) * 1000);
					if (new Date(contact.date_time) < newDate) {
						contact.content = data.message_content;
						contact.date_time = newDate.toISOString();
						contact.direction = 'in';
						contact.type = data.message_type;
					}

					found = true;
				}
			});

			if (!found) {
				this.contacts.push({
					contact_name: data.message_sender_name,
					contact_number: data.message_sender,
					content: data.message_content,
					date_time: new Date(Number(data.message_time) * 1000),
					direction: 'in',
					message_id: data.message_id,
					type: data.message_type,
				});
			}

			this.contacts.sort((a: any, b: any) => {
				return (
					new Date(b.date_time).getTime() -
					new Date(a.date_time).getTime()
				);
			});
		}
	}
}
