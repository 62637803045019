import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { InstancesService } from './instances.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class WebSocketService {
	webSocket!: WebSocket;
	private message = new Subject<any>();
	private status = new Subject<any>();
	constructor(private instancesService: InstancesService) {}

	openWebSocketConnection() {
		// get query params from url
		const url = new URL(window.location.href);
		const user_id = url.searchParams.get('uid');
		const first_name = window.sessionStorage.getItem('first_name');
        const last_name = window.sessionStorage.getItem('last_name');
		const org_id = window.sessionStorage.getItem('org_id');
		this.webSocket = new WebSocket(
			`${environment.websocketUrl}?user_id=${user_id}&user_name=${
				first_name + '_' + last_name
			}&org_id=${org_id}`
		);

		this.webSocket.onopen = (event) => {
			console.log('Connection established');
			this.webSocket.send('connect');
            setInterval(() => {
                this.webSocket.send('ping');
			}, 12000);
        };

		this.webSocket.onmessage = (event) => {
			let parsedData;
			try {
				parsedData = JSON.parse(event.data);
			} catch {
				parsedData = event.data;
			}
			// console.log(parsedData);
			if (Array.isArray(parsedData)) {
				parsedData.forEach((message) => {
					console.log(message);
					if (message.message_sender) {
						this.receiveMessage(message);
					} else if (message.message_status) {
						this.receiveMessageStatus(message);
					} else {
						console.log('unknown data received');
					}
				});
			} else if (parsedData === 'pong') {
				return;
			} else {
				window.sessionStorage.setItem('connectionId', parsedData);
				this.instancesService.getInstances().subscribe((data: any) => {
					if (
						window.sessionStorage.getItem('instances') !==
						JSON.stringify(data)
					) {
						window.sessionStorage.setItem(
							'instances',
							JSON.stringify(data)
						);
						window.sessionStorage.setItem('instance', data[0].id);
					}
				});
				return;
			}
		};

		this.webSocket.onclose = (event) => {
			console.log('Connection closed');
			console.log(event);
		};
	}

	receiveMessage(message: any) {
		this.message.next(message);
	}

	dispatchMessage(): Observable<any> {
		return this.message.asObservable();
	}

	receiveMessageStatus(status: any) {
		this.status.next(status);
	}

	dispatchMessageStatus(): Observable<any> {
		return this.status.asObservable();
	}

	closeWebSocketConnection() {
		this.webSocket.close();
	}
}
