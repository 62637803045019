<!-- <div class="options">
	<a href="#"><i class="fas fa-angle-down text-muted px-2"></i></a>
</div> -->
<ng-container *ngIf="type == 'text'">
	<div class="ps-1 fw-bold" *ngIf="message.sender_name">{{ message.sender_name.replace("_", " ") }}</div>
	<div class="d-flex flex-row">
		<div class="body m-1 me-2">{{ message.content }}</div>
		<div
			class="time ms-auto small text-end flex-shrink-0 align-self-end text-muted"
			style="width: 75px"
		>
			{{ message.time }}
			<fa-icon
				[icon]="
					status === 'pending'
						? faClock
						: status === 'sent'
						? faCheck
						: status === 'delivered' || status === 'read'
						? faCheckDouble
						: faCircleExclamation
				"
				[style.color]="status === 'read' ? '#5dd1ee' : ''"
				*ngIf="message.direction == 'out'"
			></fa-icon>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="type == 'image'">
	<div class="d-flex flex-column">
        <div class="ps-1 fw-bold" *ngIf="message.sender_name">{{ message.sender_name.replace("_", " ") }}</div>
		<div class="body m-1 me-2">
			<img
				[src]="message.content"
				alt="image"
				height="auto"
				width="auto"
			/>
		</div>
		<div
			class="time ms-auto small text-end flex-shrink-0 align-self-end text-muted"
			style="width: 75px"
		>
			{{ message.time }}
			<fa-icon
				[icon]="
					status === 'pending'
						? faClock
						: status === 'sent'
						? faCheck
						: status === 'delivered' || status === 'read'
						? faCheckDouble
						: faCircleExclamation
				"
				[style.color]="status === 'read' ? '#5dd1ee' : ''"
				*ngIf="message.direction == 'out'"
			></fa-icon>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="type == 'document'">
	<div class="d-flex flex-column">
        <div class="ps-1 fw-bold" *ngIf="message.sender_name">{{ message.sender_name.replace("_", " ") }}</div>
		<a href="{{ message.content }}" target="_blank" download>
			<div class="body m-1 me-2">
				<fa-icon [icon]="faFileLines" size="3x"></fa-icon>
			</div>
		</a>
		<div
			class="time ms-auto small text-end flex-shrink-0 align-self-end text-muted"
			style="width: 75px"
		>
			{{ message.time }}
			<fa-icon
				[icon]="
					status === 'pending'
						? faClock
						: status === 'sent'
						? faCheck
						: status === 'delivered' || status === 'read'
						? faCheckDouble
						: faCircleExclamation
				"
				[style.color]="status === 'read' ? '#5dd1ee' : ''"
				*ngIf="message.direction == 'out'"
			></fa-icon>
		</div></div
></ng-container>

<ng-container *ngIf="type == 'template'">
	<div class="d-flex flex-column">
        <div class="ps-1 fw-bold" *ngIf="message.sender_name">{{ message.sender_name.replace("_", " ") }}</div>
		<div class="body m-1 me-2">
			{{ message.content }}
		</div>
		<div
			class="time ms-auto small text-end flex-shrink-0 align-self-end text-muted"
			style="width: 75px"
		>
			{{ message.time }}
			<fa-icon
				[icon]="
					status === 'pending'
						? faClock
						: status === 'sent'
						? faCheck
						: status === 'delivered' || status === 'read'
						? faCheckDouble
						: faCircleExclamation
				"
				[style.color]="status === 'read' ? '#5dd1ee' : ''"
				*ngIf="message.direction == 'out'"
			></fa-icon>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="type == 'audio'">
	<div class="d-flex flex-column">
        <div class="ps-1 fw-bold" *ngIf="message.sender_name">{{ message.sender_name.replace("_", " ") }}</div>
		<div class="body m-1 me-2">
			<audio controls>
				<source [src]="message.content" type="audio/mpeg" />
			</audio>
		</div>
		<div
			class="time ms-auto small text-end flex-shrink-0 align-self-end text-muted"
			style="width: 75px"
		>
			{{ message.time }}
			<fa-icon
				[icon]="
					status === 'pending'
						? faClock
						: status === 'sent'
						? faCheck
						: status === 'delivered' || status === 'read'
						? faCheckDouble
						: faCircleExclamation
				"
				[style.color]="status === 'read' ? '#5dd1ee' : ''"
				*ngIf="message.direction == 'out'"
			></fa-icon>
		</div>
	</div>
</ng-container>
