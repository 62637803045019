import { Injectable } from '@angular/core';
import { WebSocketService } from './web-socket.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(private webSocketService: WebSocketService) {}

	requestPermission(): Observable<any> {
		if (Notification.permission === 'default')
			return new Observable((observer) => {
				Notification.requestPermission().then((permission) => {
					observer.next(permission);
				});
			});
		else
			return new Observable((observer) =>
				observer.next(Notification.permission)
			);
	}

	listenForMessages(): void {
		this.webSocketService.dispatchMessage().subscribe((data: any) => {
			this.handleMessage(data);
		});
	}

	handleMessage(data: any) {
		if (document.visibilityState === 'visible') return;
		if (data.message_type === 'text') {
			console.log(data);

			let notification = new Notification(
				data.message_sender_name ??
					'+' +
						data.message_sender.substr(0, 3) +
						' ' +
						data.message_sender.substr(3, 3) +
						' ' +
						data.message_sender.substr(6),
				{
					body: data.message_content,
					icon: 'assets/user_512.png',
				}
			);
			notification.onclick = () => {
				window.focus();
			};
		} else if (data.message_type === 'image') {
			console.log(data);

			let notification = new Notification(
				data.message_sender_name ??
					'+' +
						data.message_sender.substr(0, 3) +
						' ' +
						data.message_sender.substr(3, 3) +
						' ' +
						data.message_sender.substr(6),

				{
					body: 'Image',
					icon: 'assets/dsaad212312aGEA12ew.png',
				}
			);
			notification.onclick = () => {
				window.focus();
			};
		}
	}
}
