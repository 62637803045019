import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.css'],
})
export class SideMenuComponent {
	page: string = 'chat';
	@Input() state: boolean = false;

	constructor() {}
}
