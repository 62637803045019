import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class InstancesService {
	constructor(private http: HttpClient) {}

	getInstances(): Observable<any> {
		return this.http.get(
			`${environment.apiUrl}?type=instances&connection_id=${window.sessionStorage.getItem(
				'connectionId'
			)}`
		);
	}
}
