

<app-side-menu
	[class]="[
		'col-12',
		'col-md-4',
		'col-sm-5',
		'd-flex',
		'flex-column',
		'position-relative',
		'p-0',
		show ? '' : 'd-none',
		!(isMobile&&contact)?'':'d-none'
	]"
	[id]="['chat-list-area']"
	*ngIf="show"
    [state]="logged_in"
>
</app-side-menu>

<app-chat-window
	style="overflow-x: hidden"
	[class]="[
		'd-sm-flex',
		'flex-column',
		'col-12',
		(contact||(!isMobile))?'':'d-none',
		show ? 'col-md-8' : '',
		show ? 'col-sm-7' : '',
		'h-100',
		'p-0',
		'position-relative'
	]"
	[id]="['message-area']"
    [state]="logged_in"
></app-chat-window>
