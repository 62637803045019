import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebSocketService } from '../services/web-socket.service';
import { NotificationsService } from '../services/notifications.service';
import { ConversationsService } from '../services/conversations.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import {environment} from "../../environments/environment";

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
	show: boolean = true;
    page: string = 'chat';
    logged_in: boolean = false;

	constructor(
		public webSocketService: WebSocketService,
		private NotificationService: NotificationsService,
		private conversationsService: ConversationsService,
		private http: HttpClient,
		public dialog: MatDialog
	) {}

	replaceGetAscii(value: any): string {
		if (value === null || value === undefined) {
			return '';
		} else {
			return encodeURIComponent(value.toString());
		}
	}

	prepareUrlParams(url: string, params: any = {}): string {
		let paramsUrl = '?';
		Object.entries(params).forEach(([key, value], index) => {
			paramsUrl +=
				index === 0
					? key + '=' + this.replaceGetAscii(value)
					: '&' + key + '=' + this.replaceGetAscii(value);
		});
		return url + paramsUrl;
	}

	ngOnInit(): void {
		this.http
			.post(
				environment.zoho_check_endpoint,
				{
					id: new URLSearchParams(window.location.search).get('uid'),
					org: new URLSearchParams(window.location.search).get('org'),
				}
			)
			.subscribe({
				next: (data: any) => {
					window.sessionStorage.setItem(
						'first_name',
						data['first_name']
					);
					window.sessionStorage.setItem(
						'last_name',
						data['last_name']
					);
                    window.sessionStorage.setItem('org_id', data['org_id']);

                    window.sessionStorage.setItem('uid', new URLSearchParams(window.location.search).get('uid') || '');

                    this.logged_in = true;

					this.webSocketService.openWebSocketConnection();

					this.NotificationService.requestPermission();

					this.NotificationService.listenForMessages();

					const url = new URL(window.location.href);
					let phone = url.searchParams.get('phone');
					if (phone) {
						// formatted phone number
						phone = phone.replace(/\D/g, '');
						url.searchParams.set('phone', phone);
						window.history.pushState({}, '', url.toString());

						this.show = false;
						let waitForinstance = setInterval(() => {
							// console.log('waiting for instance');
							if (window.sessionStorage.getItem('instance')) {
								clearInterval(waitForinstance);
								this.conversationsService.getConversation(
									{
										contact_number: phone,
									},
									window.sessionStorage.getItem('instance')
								);
							}
						}, 100);
					}
				},

				error: (error) => {
					window.sessionStorage.clear();
				},
				complete: () => {
					console.log('complete');
				},
			});
	}

	getInstancesList() {
		const dialogRef = this.dialog.open(UploadDialogComponent, {
			width: 'auto',
			height: 'auto',
			data: {
				type: 'instances',
			},
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log(result);
			if (result) {
				window.sessionStorage.setItem('instance', result);
				window.location.reload();
			}
		});
	}

	ngOnDestroy(): void {
		this.webSocketService.closeWebSocketConnection();
	}
}
